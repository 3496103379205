import { LoadApp } from 'single-spa-layout/dist/types/browser/constructApplications';

export const REVIEW_BUCKET_URI = 'lighthouse-frontend-reviews.shift4payments.com';

// Hostname should match pattern: $CI_COMMIT_REF_SLUG.review-$APP_NAME.mf-lighthouse-frontend-staging.harbortouch.com
export const parseHostname = (appName: string, hostname: string) => {
	const subdomains = hostname.split('.');
	const subdomainApp = subdomains[1];
	if (subdomains.length < 5 || !subdomainApp.includes('review-')) {
		return {
			isReview: false,
		};
	}
	const reviewAppName = subdomainApp.substring(subdomainApp.indexOf('-') + 1);
	if (reviewAppName !== appName) {
		return {
			isReview: false,
		};
	}
	return {
		isReview: true,
		path: subdomains[0],
	};
};

export const loadApp: LoadApp = async ({ name }) => {
	if (process.env.NODE_ENV === 'development') {
		return System.import(name);
	}
	try {
		const appName = name.split('/')[1];
		const { isReview, path } = parseHostname(appName, window.location.hostname);
		// if it isReview load app from review bucket ELSE load app from staging bucket

		const manifestUri = isReview ? `https://${path}.${REVIEW_BUCKET_URI}/manifest.json` : `/${appName}/manifest.json`;
		const res = await fetch(manifestUri, {
			headers: {
				'Content-Type': 'application/json',
				'Cache-Control': 'no-cache',
				Accept: 'application/json',
			},
		});
		if (res.ok) {
			const manifest = await res.json();
			// Get the application file from the manifest based on the environment
			const mainJs = isReview
				? `https://${path}.${REVIEW_BUCKET_URI}/${manifest['main.js']}`
				: `/${appName}/${manifest['main.js']}`;
			return System.import(mainJs);
		}
		return System.import(`/${appName}/shift4-${appName}.js`);
	} catch (err) {
		console.error('CATASTROPHIC FAILURE WHEN LOADING APP SHELL', err);
		// pass a bad file to ensure catastrpohic failure
		return System.import('/frontend-navigation/bad-file-load.js');
	}
};
