import { type LDSingleKindContext, initialize } from 'launchdarkly-js-client-sdk';

export const checkIsMFsIsAvailable = async () => {
	const token = getTokenData();
	if (!token) {
		return false;
	}
	return isFFEnabled(token);
};

const LOCAL_STORAGE_AUTH_KEY = 'ember_simple_auth-session';
interface TokenData {
	token: string;
	email: string;
	firstName: string;
	lastName: string;
	parentUserId: string | null;
	permissions: { l: number; p: number }[];
	authenticator: string;
	sub: number;
}
const getTokenData = () => {
	const authStorageData = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY) ?? '{}';
	const authStorage = JSON.parse(authStorageData) as { authenticated?: TokenData };
	return authStorage?.authenticated?.token && authStorage?.authenticated?.sub ? authStorage?.authenticated : null;
};

const isFFEnabled = async (token: TokenData) => {
	const user: LDSingleKindContext = {
		kind: 'user',
		key: `${token.sub}`,
		email: token.email,
		name: '',
		custom: {
			customerName: `${token.firstName} ${token.lastName}`.trim(),
		},
	};
	// TODO Refactor to use dotenv
	const ldClientId = process.env.NODE_ENV === 'production' ? '614022e9694e8d262b59f82f' : '62695639cb0596159e560770';
	const client = initialize(ldClientId, user);
	await client.waitUntilReady();
	return client.variation('single-spa-phased-rollout', false);
};

export const checkHeader = (navigationElement: HTMLElement, timeout = 30000) =>
	new Promise((resolve, reject) => {
		const startTime = Date.now();

		const header = navigationElement.querySelector('header');
		if (header) {
			resolve(header);
		} else {
			const interval = setInterval(() => {
				const checkHeader = navigationElement.querySelector('header');
				if (checkHeader) {
					clearInterval(interval);
					resolve(checkHeader);
				} else if (Date.now() - startTime >= timeout) {
					clearInterval(interval);
					reject(new Error('frontend-navigation header element not found'));
				}
			}, 100);
		}
	});
