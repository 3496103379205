import { REVIEW_BUCKET_URI, parseHostname } from './asset-loader';

const loadCSS = (url: string) => {
	const head = document.getElementsByTagName('head')[0];
	const style = document.createElement('link');
	style.href = url;
	style.type = 'text/css';
	style.rel = 'stylesheet';
	head.append(style);
};

const loadEmberUrls = async () => {
	const assetData: { isReview: boolean; path?: string } =
		process.env.NODE_ENV === 'development' ? { isReview: false } : parseHostname('lh4', window.location.hostname);

	const getReviewUri = (path: string) =>
		assetData.isReview ? `https://${assetData.path}.${REVIEW_BUCKET_URI}/${path}` : `/${path}`;

	try {
		const response = await fetch(getReviewUri('assets/assetMap.json'));
		if (response.ok) {
			const { assets } = await response.json();
			loadCSS(getReviewUri(assets['assets/vendor.css']));
			loadCSS(getReviewUri(assets['assets/lh4.css']));
			return {
				appUrl: getReviewUri(assets['assets/lh4.js']),
				vendorUrl: getReviewUri(assets['assets/vendor.js']),
			};
		}
	} catch (err) {
		console.error({ err });
	}
	return {};
};

export default loadEmberUrls;
